import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/header";
import Banner from "./components/banner";
import About from "./components/about";
import Verify from "./components/verifyCard";
import BuyCard from "./components/buyCard";
import Reviews from "./components/reviews";
import Footer from "./components/footer";
import Loader from "./components/loader";

function App() {
  const [loading, setLoading] = useState(false);

  return (
    <div className="App">
      {loading && <Loader />}
      <Header />
      <ToastContainer />
      <Banner />
      <About />
      <Verify setLoading={setLoading} />
      <BuyCard />
      <Reviews />
      <Footer />
    </div>
  );
}

export default App;
