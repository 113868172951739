import { giftCardArray } from "../../data/cardData";

import "./style.css";

const BuyCard = () => {
  return (
    <div id="buy" className="buy">
      <h2>GIFT CARDS OF TOP BRANDS AVAILABLE</h2>
      <div className="cards">
        {giftCardArray.map((card) => (
          <div key={card.name} className="card">
            <img src={card.img} alt={card.name} />
            <div>
              <h3
                style={{
                  width: "240px",
                }}
              >
                {card.name} Gift Cards
              </h3>
              <p>Original Price: ${card.original}</p>
              <button> Buy for ${card.buy.toFixed(2)}</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuyCard;
