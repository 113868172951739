import svgImg from "../../assets/award.svg";
import "./style.css";

const Footer = () => {
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img src={svgImg} alt="Award" width={200} className="award-icon" />
      </div>

      <footer className="footer">
        <p>&copy; 2018 Giftcardsbal. All Rights Reserved</p>
      </footer>
    </>
  );
};

export default Footer;
