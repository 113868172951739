import ebayImg from "../assets/giftCardImg/ebay.jfif";
import amazonImg from "../assets/giftCardImg/amazon.jfif";
import appleImg from "../assets/giftCardImg/Apple.jpg";
import googleImg from "../assets/giftCardImg/google-play.jfif";
import nordstromImg from "../assets/giftCardImg/nordstrom.png";
import sephoraImg from "../assets/giftCardImg/sephora.jpg";
import spotifyImg from "../assets/giftCardImg/spotify.jfif";
import nikeImg from "../assets/giftCardImg/nike.jpg";
import americanXpImg from "../assets/giftCardImg/americanXp.jpg";
import steamImg from "../assets/giftCardImg/steam.webp";
import xboxImg from "../assets/giftCardImg/xbox.jfif";
import razorImg from "../assets/giftCardImg/razor_gold.jpeg";
import vanillaImg from "../assets/giftCardImg/vanilla.jpg";
import macysImg from "../assets/giftCardImg/macys.webp";
import footlockerImg from "../assets/giftCardImg/footlocker.png";

export const giftCardArray = [
  {
    name: "ebay",
    img: ebayImg,
    original: 120,
    buy: 120 - 0.01 * 120,
  },
  {
    name: "Amazon",
    img: amazonImg,
    original: 150,
    buy: 150 - 0.01 * 150,
  },
  {
    name: "Apple",
    img: appleImg,
    original: 100,
    buy: 100 - 0.01 * 100,
  },
  {
    name: "Google Play",
    img: googleImg,
    original: 50,
    buy: 50 - 0.01 * 50,
  },
  {
    name: "Nordstrom",
    img: nordstromImg,
    original: 200,
    buy: 200 - 0.01 * 200,
  },
  {
    name: "Sephora",
    img: sephoraImg,
    original: 75,
    buy: 75 - 0.01 * 75,
  },
  {
    name: "Spotify",
    img: spotifyImg,
    original: 30,
    buy: 30 - 0.01 * 30,
  },
  {
    name: "Nike",
    img: nikeImg,
    original: 80,
    buy: 80 - 0.01 * 80,
  },
  {
    name: "American Express",
    img: americanXpImg,
    original: 150,
    buy: 150 - 0.01 * 150,
  },
  {
    name: "Steam",
    img: steamImg,
    original: 50,
    buy: 50 - 0.01 * 50,
  },
  {
    name: "Xbox",
    img: xboxImg,
    original: 60,
    buy: 60 - 0.01 * 60,
  },
  {
    name: "Razer Gold",
    img: razorImg,
    original: 25,
    buy: 25 - 0.01 * 25,
  },
  {
    name: "Vanilla",
    img: vanillaImg,
    original: 50,
    buy: 50 - 0.01 * 50,
  },
  {
    name: "Macys",
    img: macysImg,
    original: 100,
    buy: 100 - 0.01 * 100,
  },
  {
    name: "Foot Locker",
    img: footlockerImg,
    original: 75,
    buy: 75 - 0.01 * 75,
  },
];
