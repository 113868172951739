import about1 from "../../assets/about.jfif";
import about2 from "../../assets/about2.png";

import "./style.css";

const About = () => {
  return (
    <div className="about" id="about">
      <div className="container">
        <div>
          <h2>VALIDATE GIFT CARDS SWIFTLY WITHOUT PROBLEM.</h2>
          <p>
            We are your No.1 Stop for verifying digital gift cards. We have a
            wide selection of gift cards from popular brands across various
            categories, including fashion, dining, entertainment, and more that
            you can verify.
          </p>
          <a href="#verify">
            <button>Verify Giftcard</button>
          </a>
        </div>
        <img src={about1} alt="Verify" width={550} />
      </div>
      <div className="container">
        <img src={about2} alt="Purchase" width={550} />

        <div>
          <h2>PURCHASE GIFT CARDS SWIFTLY WITHOUT PROBLEM.</h2>
          <p>
            We are your No.1 Stop for verifying digital gift cards. We have a
            wide selection of gift cards from popular brands across various
            categories, including fashion, dining, entertainment, and more that
            you purchase.
          </p>
          <a href="#buy">
            <button>Purchase Giftcard</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
