export const cardsArray = [
  "Amazon",
  "Apple",
  "eBay",
  "Googleplay",
  "Mastercard",
  "Nike",
  "Nordstorm",
  "Playstation",
  "Razer Gold",
  "Sephora",
  "Steam",
  "TT Visa",
  "Vanilla Visa",
  "Visa Silvery White",
  "Walmart Visa",
  "Xbox",
];

export const currency = [
  "USD (Dollar)",
  "CAD (Canada $)",
  "AUD (Austrailia $)",
  "EUR (Euros)",
  "GBP (Pounds)",
];
