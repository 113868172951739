import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import the main Swiper CSS file
import { useState, useEffect, useRef } from "react";

import { reviews } from "../../data/review";
import "./style.css";

const Reviews = () => {
  const [swiper, setSwiper] = useState(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
      setSwiper(swiperRef.current.swiper);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper) {
        swiper.slideNext();
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [swiper]);

  return (
    <div className="review" id="review">
      <h2>REVIEWS & FEEDBACKS</h2>

      <Swiper ref={swiperRef} spaceBetween={40} slidesPerView={1} loop={true}>
        {reviews.map(({ name, text }, index) => (
          <SwiperSlide key={index} className="review-container">
            <div className="review-content">
              <h3>
                {name}
                <small>giftcardsbal exchange</small>
              </h3>
              <p>{text}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Reviews;
