import {
  FaBars,
  FaHome,
  FaInfo,
  FaCheck,
  FaShoppingCart,
  FaStar,
} from "react-icons/fa";
import { useState } from "react";

import icon from "../../assets/icon.png";
import "./style.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header className="header">
        <div className="logo">
          <img src={icon} alt="Logo" width={50} />
          <h2>Giftscardbal</h2>
        </div>
        <nav className="nav">
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#verify">Verify Giftcard</a>
          <a href="#buy">Buy Card</a>
          <a href="#review">Testimonials</a>
        </nav>
      </header>

      <header className="mobile-header">
        <div className="logo">
          <img src={icon} alt="Logo" width={40} />
          <h2>Giftscardbal</h2>
        </div>
        <div
          className={`menu-icon ${isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          {!isMenuOpen && <FaBars size={25} />}

          {isMenuOpen && (
            <nav className="mobile-nav">
              <span className="close-button" onClick={toggleMenu}>
                &times;
              </span>
              <a href="#home">
                <FaHome /> Home
              </a>
              <a href="#about">
                <FaInfo /> About
              </a>
              <a href="#verify">
                <FaCheck /> Verify Giftcard
              </a>
              <a href="#buy">
                <FaShoppingCart /> Buy Card
              </a>
              <a href="#review">
                <FaStar /> Testimonials
              </a>
            </nav>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
