import homepic from "../../assets/Home Pic.png";
import "./style.css";

const Banner = () => {
  return (
    <div className="banner" id="home">
      <div>
        <h2>PURCHASE AND VERIFY DIGITAL GIFT CARDS.</h2>
        <p>
          Your No.1 stop for any gift card purchase and validation - swift
          response, top-knotch security and trusted by over 5,000 users daily.
        </p>
        <a href="#verify">
          <button>Verify Giftcard</button>
        </a>
      </div>
      <img src={homepic} alt="home" width={600} />
    </div>
  );
};

export default Banner;
