export const reviews = [
  { name: "Mark Smith", text: "Amazing quality! Will buy again." },
  { name: "Sarah Jones", text: "Superb service and quick delivery." },
  { name: "Chris White", text: "Impressed with the product's durability." },
  { name: "Laura Brown", text: "Excellent value for the price." },
  { name: "Alex Turner", text: "Top-notch customer support." },
  {
    name: "Emma Miller",
    text: "Fantastic product, exceeded expectations. The attention to detail in design is remarkable. It has truly elevated my user experience, and I can't recommend it enough.",
  },
  { name: "Jake Davis", text: "Fast shipping and well-packaged." },
  { name: "Olivia Taylor", text: "Highly recommended, five stars!" },
  {
    name: "Ryan Moore",
    text: "Great purchase, very satisfied. The product's versatility has made it an integral part of my daily routine. I appreciate the quality craftsmanship.",
  },
  { name: "Ella Wilson", text: "Quality craftsmanship, love it!" },
  { name: "Luke Reed", text: "Smooth transaction, no issues." },
  {
    name: "Ava Hall",
    text: "Impressive quality, worth every penny. The product arrived well before I expected, showcasing their commitment to prompt delivery.",
  },
  {
    name: "Max King",
    text: "Timely delivery and great communication. The after-sales service was prompt and helpful, ensuring a smooth post-purchase experience.",
  },
  { name: "Grace Hill", text: "Exactly what I was looking for." },
  { name: "Leo Foster", text: "Satisfied customer, will return." },
  {
    name: "Zoe Perry",
    text: "Well-made product, durable and stylish. An excellent balance of form and function; it's both aesthetically pleasing and practical.",
  },
  { name: "Caleb Ross", text: "Prompt service, reliable seller." },
  {
    name: "Lily Cox",
    text: "Exceeded my expectations, very pleased. The product not only met but exceeded my high standards; I'm a delighted customer.",
  },
  { name: "Owen Brooks", text: "Awesome product, highly recommended." },
  { name: "Maya Clark", text: "Quick shipping, hassle-free experience." },
  {
    name: "Dylan Ward",
    text: "Great value for money, superb quality. The value for money is unparalleled; I feel like I got more than what I paid for.",
  },
  { name: "Nora Foster", text: "Flawless transaction, A+ service." },
  { name: "Jack Knight", text: "Excellent customer service, very responsive." },
  {
    name: "Sophia Hill",
    text: "Sturdy and well-designed, love it! The product arrived well before I expected, showcasing their commitment to prompt delivery.",
  },
  { name: "Mia Turner", text: "Fast delivery, exactly as described." },
];
